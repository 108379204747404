import React, { useCallback, useState } from "react";
import { Pagination } from "../../../components/common/Table";
import { Article } from "../../../models";
import { limit } from "../Articles";
import ArticleTable from "./ArticleTable";
import ArticleTranscriptModal from "./ArticleTranscriptModal";
import ArticleViewModal from "./ArticleViewModal";

const ArticleTableContainer = ({
  articles,
  showPagination,
  totalArticles,
  offset,
  setOffset,
}: {
  articles: Article[];
  showPagination?: boolean;
  totalArticles?: number;
  offset?: number;
  setOffset?: (offset: number) => void;
}) => {
  const [articleInfo, setArticleInfo] = useState({
    id: "",
    name: "",
    url: "",
    action: "",
  });

  const handleArticleAction = useCallback(
    (
      id: string,
      name: string,
      url: string,
      action: "" | "view" | "download" | "transcript"
    ) => {
      setArticleInfo({ id, name, url, action });
    },
    []
  );

  return (
    <>
      <ArticleTable
        articles={articles}
        handleArticleAction={handleArticleAction}
      />
      {showPagination && (
        <Pagination
          offset={offset!}
          limit={limit}
          total={totalArticles!}
          setOffset={setOffset!}
        />
      )}
      {articleInfo.action === "view" && (
        <ArticleViewModal
          articleUrl={articleInfo.url}
          onRequestClose={() => handleArticleAction("", "", "", "")}
        />
      )}
      {articleInfo.action === "transcript" && (
        <ArticleTranscriptModal
          articleTitle={articleInfo.name}
          articleUrl={articleInfo.url}
          onRequestClose={() => handleArticleAction("", "", "", "")}
        />
      )}
    </>
  );
};

export default ArticleTableContainer;
