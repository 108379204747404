import { ViewfinderCircleIcon } from "@heroicons/react/20/solid";
import {
  ArrowDownTrayIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import Menu, { Item as MenuItem } from "rc-menu";
import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import Button from "../../../components/form/Button";
import LoadingSpinner from "../../../components/loaders/LoadingSpinner";
import { ROUTE_ARTICLE_DOWNLOAD } from "../../../constants/routes";
import useSelectedAccount from "../../../hooks/useSelectedAccount";
import {
  copyAsRichText,
  downloadAsHTML,
  downloadAsRichText,
  downloadAsText,
} from "../../../utils";
enum Formats {
  COPY_AS_RTF = "copy_as_rtf",
  HTML = "html",
  TEXT = "text",
  RICH_TEXT = "rich_text",
  PDF = "pdf",
}

const downloadOptions = [
  { key: Formats.HTML, label: "HTML", ext: ".html" },
  { key: Formats.TEXT, label: "Text", ext: ".txt" },
  { key: Formats.RICH_TEXT, label: "Rich text", ext: ".rtf" },
  { key: Formats.PDF, label: "Print / Save as PDF", ext: ".pdf" },
  { key: Formats.COPY_AS_RTF, label: "Copy to clipboard" },
];

const ArticleActions = ({
  articleId,
  articleUrl,
  onView,
}: {
  articleId: string;
  articleUrl: string;
  onView: () => void;
}) => {
  const [downloading, setDownloading] = useState(false);
  const [copied, setCopied] = useState(false);
  const { accountId } = useSelectedAccount();

  const onSelect = async ({ key }: any) => {
    const filename = articleId;
    if (key === Formats.HTML) {
      setDownloading(true);
      await downloadAsHTML(articleUrl, filename);
      setDownloading(false);
    } else if (key === Formats.TEXT) {
      setDownloading(true);
      await downloadAsText(articleUrl, filename);
      setDownloading(false);
    } else if (key === Formats.RICH_TEXT) {
      setDownloading(true);
      await downloadAsRichText(articleUrl, filename);
      setDownloading(false);
    } else if (key === Formats.COPY_AS_RTF) {
      setDownloading(true);
      copyAsRichText(articleUrl)
        .then(() => {
          setDownloading(false);
          showCheck();
        })
        .catch(() => {
          setDownloading(false);
          // showCheck();
        });
    } else if (key === Formats.PDF) {
      window.open(
        generatePath(ROUTE_ARTICLE_DOWNLOAD, { id: articleId }) +
          `?accountId=${accountId}`,
        "_blank"
      );
    }
  };

  const menu = (
    <Menu
      onClick={onSelect}
      className="text-sm"
      selectable={false}
      style={{ padding: 4, minWidth: 180 }}
    >
      {downloadOptions.map((option) => (
        <MenuItem
          key={option.key}
          className="hover:bg-gray-0 hover:text-primary flex gap-1 items-center justify-between"
          style={{ padding: "6px 8px", cursor: "pointer" }}
        >
          <div className="flex gap-1">{option.label}</div>
          {option.key === Formats.PDF && (
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          )}
          {option.key === Formats.COPY_AS_RTF && (
            <Square2StackIcon className="w-4 h-4" />
          )}
        </MenuItem>
      ))}
    </Menu>
  );
  const showCheck = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div className="flex items-center gap-0.5">
      <Button
        paddingZero
        variant="light-primary"
        className="flex gap-2 items-center p-1 uppercase text-xs"
        tooltip="View Article"
        onClick={onView}
      >
        <ViewfinderCircleIcon className="w-4 h-4 md:hidden" />
        View
      </Button>
      <div className="h-4 bg-gray-200 w-px" />
      <div>
        {copied && <CheckIcon className="w-4 h-4 mt-1 text-green-600" />}
        {!copied && downloading && <LoadingSpinner size="16px" />}
        {!copied && !downloading && (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
          >
            <Button
              paddingZero
              variant="light-primary"
              className="flex gap-2 items-center p-1"
              tooltip="Download Article"
              onClick={() => {}}
            >
              <ArrowDownTrayIcon className="w-4 h-4" />{" "}
              <span className="md:hidden">Download</span>
            </Button>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default ArticleActions;
